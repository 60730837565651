import { Table, ActionIcon, Checkbox, UnstyledButton } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {
    IconEdit,
    IconTrash,
    IconBell
} from '@tabler/icons-react';
import FilteringModal from '../FilteringModal/FilteringModal.tsx';
import styles from './Page.module.css';
import DeleteWarning from '../DeleteWarning/DeleteWarning.tsx';
import WebhookManager from '../WebhookManagement/NotifModal.tsx';

interface Item {
    eventid: string;
    eventname: string;
    eventDate: string;
    eventlocation: string;
}

interface PageProps {
    data: Item[];
    activePage: number;
    quantity: number;
    setAllEvents: (value: any) => void;
    setChecked: (value: any) => void;
    checkedBoxes: string[];
    setEventAdded: (value: any) => void;
}

function chunk<T>(array: T[], size: number): T[][] {
    if (!array.length) {
        return [];
    }
    const head = array.slice(0, size);
    const tail = array.slice(size);
    return [head, ...chunk(tail, size)];
}

export default function Page({ data, activePage, quantity, checkedBoxes, setChecked, setAllEvents, setEventAdded, enterprise }: PageProps) {
    const chunkedData = chunk(data, quantity);
    const [isOpen, setIsOpen] = useState(false);
    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [eventToTrash, setEventToTrash] = useState<any[]>([]);
    const [isManagerOpen, setIsManagerOpen] = useState(false);
    const [currentEvent, setCurrentEvent] = useState("");

    const openModal = (data) => {
        setSelectedData(data);
        setIsOpen(true);
    };

    const closeModal = () => {
        setSelectedData(null);
        setIsOpen(false);
    };

    function handleTrash(eventid) {
        setIsWarningOpen(true);
        setEventToTrash([eventid]);
    }

    function handleNotifManager(eventid) {
        setIsManagerOpen(true);
        setCurrentEvent(eventid);
    }

    useEffect(() => {
        if (data.length > 0) {
            setAllEvents(chunkedData[activePage - 1].map(item => item.eventid));
        }
    }, [activePage, quantity, data]);

    const items = chunkedData[activePage - 1] && chunkedData[activePage - 1].map((item: Item) => (
        <Table.Tr key={item.eventid}>
            <Table.Td>
                <Checkbox
                    onChange={() => {
                        checkedBoxes.includes(item.eventid) ? setChecked(checkedBoxes.filter((id) => id !== item.eventid)) : setChecked([...checkedBoxes, item.eventid]);
                    }}
                    checked={checkedBoxes.includes(item.eventid)}
                />
            </Table.Td>
            <Table.Td>
                <a
                    href={`https://ticketmaster.com/event/${item.eventid}`}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.eventid}
                >
                    {item.eventid}
                </a>
            </Table.Td>
            <Table.Td>{item.eventname}</Table.Td>
            <Table.Td>{item.eventDate}</Table.Td>
            <Table.Td>{item.eventlocation}</Table.Td>
            <Table.Td>
                <UnstyledButton onClick={() => openModal(item)}>
                    <ActionIcon variant="transparent" size="lg" aria-label="Edit">
                        <IconEdit stroke={1} />
                    </ActionIcon>
                </UnstyledButton>

                <UnstyledButton onClick={() => handleTrash(item.eventid)}>
                    <ActionIcon variant="transparent" size="lg" aria-label="Trash" color="red" >
                        <IconTrash stroke={1} />
                    </ActionIcon>
                </UnstyledButton>

                {enterprise && <UnstyledButton onClick={() => handleNotifManager(item.eventid)}>
                    <ActionIcon variant="transparent" size="lg" aria-label="Trash" color="teal" >
                        <IconBell stroke={1} />
                    </ActionIcon>
                </UnstyledButton>}
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <>
            {items}
            <FilteringModal opened={isOpen} close={closeModal} eventitem={selectedData} />
            <DeleteWarning
                isWarningOpen={isWarningOpen}
                setIsWarningOpen={setIsWarningOpen}
                eventToTrash={eventToTrash}
                setEventAdded={setEventAdded}
            />
            <WebhookManager isManagerOpen={isManagerOpen} setIsManagerOpen={setIsManagerOpen} currentEvent={currentEvent} />
        </>
    )
}