import { useState } from 'react';
import {
    Table,
    Select,
    UnstyledButton,
    Group,
    Text,
    Center,
    Pagination,
    rem,
    keys,
    Button,
    TextInput,
    Checkbox,
    Stack,
    ScrollArea
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import classes from './TableSort.module.css';
import React, { useEffect } from 'react';
import AddEventModal from '../AddEvents/AddEventModal.tsx';
import Page from '../Pagination/Page.tsx';
import DeleteWarning from '../DeleteWarning/DeleteWarning.tsx';
import WebhookManager from '../WebhookManagement/ManageModal.tsx';
import BulkNotifModal from '../WebhookManagement/BulkNotifModal.tsx';

const IconSvg = process.env.PUBLIC_URL + "/EmptyPage.svg";

interface RowData {
    eventid: string;
    eventname: string;
    eventDate: string;
    eventlocation: string;
}

interface ThProps {
    children: React.ReactNode;
    reversed?: boolean;
    sorted?: boolean;
    onSort?: () => void;
}

interface Item {
    eventid: string;
    eventname: string;
    eventDate: string;
    eventlocation: string;
}

interface PageProps {
    data: Item[];
    page: number;
    setPage: (page: number) => void;
    setEventAdded: (value: any) => void;
    allotedSlots: number;
    usedSlots: number;
    enterprise: boolean;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data: RowData[], search: string) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data: RowData[],
    payload: { sortBy: keyof RowData | null; reversed: boolean; search: string }
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    const comparator = (a: RowData, b: RowData) => {
        if (sortBy === 'eventDate') {
            const dateA = new Date(a.eventDate);
            const dateB = new Date(b.eventDate);
            if (payload.reversed) {
                return dateB.getTime() - dateA.getTime();
            }
            return dateA.getTime() - dateB.getTime();
        }

        if (payload.reversed) {
            return b[sortBy].localeCompare(a[sortBy]);
        }

        return a[sortBy].localeCompare(b[sortBy]);
    };

    return filterData([...data].sort(comparator), payload.search);
}

export default function TableSort({ page, data, setPage, setEventAdded, allotedSlots, usedSlots, enterprise }: PageProps) {
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState(data);
    const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const [selectedValue, setSelectedValue] = useState('10');
    const [allEventsPage, setAllEventsPage] = useState([]);
    const [checkedBoxes, setChecked] = useState<string[]>([]);
    const [isWarningOpen, setIsWarningOpen] = useState(false);
    const [webhookManager, setWebhookManager] = useState(false);
    const [bulkManager, setBulkManager] = useState(false);

    const setSorting = (field: keyof RowData) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        setSortedData(sortData(data, { sortBy: field, reversed, search }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setSearch(value);
        setSortedData(sortData(data, { sortBy, reversed: reverseSortDirection, search: value }));
        setPage(1); // Reset to the first page when a new search is performed
    };

    const displayTrash = () => {
        setIsWarningOpen(true);
    };

    const manageWebhooks = () => {
        setWebhookManager(true);
    };

    const manageBulkWebhooks = () => {
        setBulkManager(true);
    }

    const postDeletion = (eventids: string[]) => {
        const updatedEventIds = eventids.filter((id) => !checkedBoxes.includes(id));
        setEventAdded(updatedEventIds);
        setChecked([]);
    };

    useEffect(() => {
        setSortedData(data);
    }, [data]);

    const rows = sortedData.length > 0
        ? <Page setChecked={setChecked} checkedBoxes={checkedBoxes} data={sortedData} activePage={page} quantity={Number(selectedValue || 10)} setAllEvents={setAllEventsPage} setEventAdded={setEventAdded} enterprise={enterprise} />
        : [];

    const totalPages = Math.ceil(sortedData.length / Number(selectedValue));

    return (
        <>
            <BulkNotifModal 
                isModalOpen={bulkManager} 
                setModalOpen={setBulkManager} 
                currentEvents={checkedBoxes}
            />
            <WebhookManager
                isManagerOpen={webhookManager}
                setIsManagerOpen={setWebhookManager}
            />
            <DeleteWarning
                isWarningOpen={isWarningOpen}
                setIsWarningOpen={setIsWarningOpen}
                eventToTrash={checkedBoxes}
                setEventAdded={setEventAdded}
                postDeletion={postDeletion}
            />
            <AddEventModal opened={opened} close={close} setEventAdded={setEventAdded} />
            <Group justify='flex-end' mb="sm" mt="sm">
                {<Text>Using {usedSlots} of {allotedSlots} slots</Text>}
                <Button onClick={open} variant="light">Add Events</Button>

                {checkedBoxes.length > 0 && <Button variant="light" color="red"
                    onClick={() => displayTrash()}
                >Bulk Delete</Button>}

                {enterprise && checkedBoxes.length === 0 && <Button variant="light" color="teal"
                    onClick={() => manageWebhooks()}
                >Manage Webhooks</Button>}

                {enterprise && checkedBoxes.length > 0 && <Button variant="light" color="teal"
                    onClick={() => manageBulkWebhooks()}
                >Bulk Manage Webhooks</Button>}


            </Group>
            <TextInput
                placeholder="Search by any field"
                mb="md"
                leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                value={search}
                onChange={handleSearchChange}
                classNames={classes}
            />
            <Stack
                justify='space-between'
                style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            >

                <ScrollArea style={{ height: '62vh' }}>
                    <Table stickyHeader stickyHeaderOffset={0}>
                        <Table.Thead>
                            <Table.Tr>
                                <Th>
                                    <Checkbox
                                        onChange={(event) => {
                                            if (checkedBoxes.length === allEventsPage.length) {
                                                setChecked([]);
                                            } else {
                                                setChecked(allEventsPage);
                                            }
                                        }}
                                        checked={checkedBoxes.length > 0 && checkedBoxes.length === allEventsPage.length}
                                        disabled={allEventsPage.length === 0}
                                        indeterminate={checkedBoxes.length > 0 && checkedBoxes.length < allEventsPage.length}
                                    />
                                </Th>
                                <Th
                                    sorted={sortBy === 'eventid'}
                                    reversed={reverseSortDirection}
                                    onSort={() => setSorting('eventid')}
                                >
                                    Event ID
                                </Th>
                                <Th
                                    sorted={sortBy === 'eventname'}
                                    reversed={reverseSortDirection}
                                    onSort={() => setSorting('eventname')}
                                >
                                    Performer
                                </Th>
                                <Th
                                    sorted={sortBy === 'eventDate'}
                                    reversed={reverseSortDirection}
                                    onSort={() => setSorting('eventDate')}
                                >
                                    Date
                                </Th>
                                <Th
                                    sorted={sortBy === 'eventlocation'}
                                    reversed={reverseSortDirection}
                                    onSort={() => setSorting('eventlocation')}
                                >
                                    Venue
                                </Th>
                                <Th>
                                    Actions
                                </Th>
                            </Table.Tr>
                        </Table.Thead>
                        {React.Children.count(rows) > 0 ? (
                            <Table.Tbody>
                                {rows}
                            </Table.Tbody>
                        ) : null}
                    </Table>
                    {React.Children.count(rows) === 0 &&
                        <Center>
                            <img src={IconSvg} alt="Empty Page" />
                        </Center>
                    }
                </ScrollArea>

                <Center>
                    <Group>
                        <Pagination defaultValue={1} boundaries={1} siblings={1} total={totalPages} value={page} onChange={setPage} mt="sm" />
                        <Select
                            styles={{ wrapper: { width: '35%' } }}
                            data={['10', '15', '50', '100']}
                            defaultValue="10"
                            mt='sm'
                            withCheckIcon={false}
                            value={selectedValue}
                            onChange={(newVal) => {
                                setPage(1)
                                setSelectedValue(newVal || '10')
                            }}
                        />
                    </Group>
                </Center>
            </Stack>
        </>
    );
}
