import cx from 'clsx';
import { ActionIcon, useMantineColorScheme, useComputedColorScheme, Group, } from '@mantine/core';
import { IconSun, IconMoon } from '@tabler/icons-react';
import classes from './ColorModeToggle.module.css';
import React from 'react';

export default function ColorModeToggle() {
    const { setColorScheme } = useMantineColorScheme();
    const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

    return (
        <Group justify="center" mr="1%">
            <ActionIcon
                onClick={() => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')}
                variant="default"
                size="xl"
                aria-label="Toggle color scheme"
            >
                <IconMoon className={cx(classes.icon, classes.dark)} stroke={1.5} />
            </ActionIcon>
        </Group>
    );
}