import React, { useState } from 'react';
import { Modal, Button, Group } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import notifCss from '../notifications.module.css'
import { IconCheck } from '@tabler/icons-react';

interface DeleteWarningProps {
    isWarningOpen: boolean;
    setIsWarningOpen: (isOpen: boolean) => void;
    eventToTrash: any; // replace with the actual type
    setEventAdded: (isAdded: boolean) => void;
    postDeletion?: (event: any) => void; // replace with the actual type
}


export default function DeleteWarning({ isWarningOpen, setIsWarningOpen, eventToTrash, setEventAdded, postDeletion}: DeleteWarningProps) {
    const checkIcon = <IconCheck style={{ width: '20px', height: '20px' }} />;
    return (
        <Modal
            opened={isWarningOpen}
            onClose={() => setIsWarningOpen(false)}
            title={'Are you sure you want to delete this event?'}
            overlayProps={{
                backgroundOpacity: 0.55,
                blur: 3,
            }}>
            <Group>
                <Button color="red" onClick={async () => {
                    const request = fetch(`${process.env.REACT_APP_API_DOMAIN}/removeEvents`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': JSON.parse(localStorage.userData).sub
                        },
                        body: JSON.stringify(
                            eventToTrash
                        ),
                    })

                    if ((await request).ok) {
                        setEventAdded(true)
                        setIsWarningOpen(false);

                        notifications.show({
                            message: 'Events removed successfully!',
                            classNames: notifCss,
                            icon: checkIcon,
                            color: 'teal'
                        })

                        if (postDeletion) {
                            postDeletion(eventToTrash);
                        }
                    }
                }}>
                    Yes
                </Button>

                <Button variant="outline" onClick={() => {
                    setIsWarningOpen(false);
                }}>
                    No, take me back please!
                </Button>
            </Group>
        </Modal>
    )
}