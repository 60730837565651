import React, { useState, useEffect } from "react";
import { Modal, Card, Stack, Text, Group, UnstyledButton, ScrollArea } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
interface EventNotifProps {
    isManagerOpen: boolean;
    setIsManagerOpen: (isOpen: boolean) => void;
    currentEvent: string;
}

export default function NotifModal({ isManagerOpen, setIsManagerOpen, currentEvent }: EventNotifProps) {
    const [webhooks, setWebhooks] = useState<Record<string, string>>({
        "yes": "https://example.com",
        "no": "https://example.com",
    });
    const [webhooksUsed, setWebhooksUsed] = useState<string[]>([]);

    useEffect(() => {
        async function fetchWebhooks() {
            console.log("Bare in mind: ", currentEvent);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/getWebhookList`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': JSON.parse(localStorage.userData).sub
                    }
                });
                const data = await response.json();
                setWebhooks(data.webhooks);
            } catch (error) {
                console.error('Failed to fetch webhooks:', error);
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/getEventWebhooks?eventId=` + currentEvent, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': JSON.parse(localStorage.userData).sub
                    }
                });
                const data = await response.json();
                setWebhooksUsed(data.webhooks);
            } catch (error) {
                console.error('Failed to fetch event webhooks:', error);
            }
        }

        if (isManagerOpen) {
            fetchWebhooks();
        }

    }, [isManagerOpen]);

    function close() {
        setIsManagerOpen(false);

        fetch(`${process.env.REACT_APP_API_DOMAIN}/updateEventWebhooks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': JSON.parse(localStorage.userData).sub
            },
            body: JSON.stringify({
                [currentEvent]: webhooksUsed,
            }),
        });
    }

    return (
        <Modal
            opened={isManagerOpen}
            onClose={() => close()}
            title={"Webhooks to ping"}
            overlayProps={{
                backgroundOpacity: 0.55,
                blur: 3,
            }}
        >
            <ScrollArea>
                <Stack gap="xs">
                    {Object.keys(webhooks).map((key) => (
                        <UnstyledButton onClick={
                            () => {
                                if (webhooksUsed.includes(key)) {
                                    setWebhooksUsed(webhooksUsed.filter(item => item !== key));
                                } else {
                                    setWebhooksUsed(webhooksUsed => [...webhooksUsed, key]);
                                }
                            }
                        }>
                            <Card shadow="sm" padding="sm" radius="md" withBorder>
                                <Group justify="space-between">
                                    <Text>{key}</Text>
                                    {webhooksUsed.includes(key) && <IconCheck />}
                                </Group>
                            </Card>
                        </UnstyledButton>
                    ))}
                </Stack>
            </ScrollArea>

        </Modal>
    );
}
