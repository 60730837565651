import React from 'react';

export default function Settings() {

    return (
        <div>
            <h1>Settings</h1>
            <p>No content yet! Let us know of anything you'd like to see configurable in this software!</p>
        </div>
    )
}