import React, { useEffect, useState } from 'react';
import { LoadingOverlay, Group, Button, Center } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import styles from './TMMap.module.css';
import OverlayCss from './Overlay.module.css';

const prefixes = ['FLD', 'FLR', 'LOGE', 'SEC', 'MEZZ', 'ORCH', 'FLOOR', 'FL', 'QB'];

export default function TMMap({ eventid, groupid, getSelectedSections, addSection, removeSection, gaInfo }) {
    let [mapImage, setMapImage] = useState<string | null>(null);
    let [overlayData, setOverlayData] = useState<any | null>(null);
    const [visible, handlers] = useDisclosure(false);
    const [groups, setGroups] = useState<{ [key: string]: any[] }>({});

    const [hoveredSection, setHoveredSection] = useState(null);
    let selectedSections = getSelectedSections(groupid);

    useEffect(() => {
        selectedSections = getSelectedSections(groupid);
    }, [groupid]);

    async function handleClick(section: string) {
        let selectedSectionsCopy = [...selectedSections];
        if (!selectedSectionsCopy.includes(section)) {
            addSection(groupid, section);
        } else {
            removeSection(groupid, section);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (eventid) {
                console.log("CHANGE IN GA: ", gaInfo)

                handlers.open()
                const response = await fetch(`https://mapsapi.tmol.io/maps/geometry/3/event/${eventid}?systemId=HOST`);
                const jsonData = await response.json()
                let newArray: { id: string; labelData: any; path: string; }[] = [];
                let newSections: string[] = [];

                await jsonData.pages[0].segments.map((segment) => {
                    try {
                        let segmentName = segment.segments[0].name;
                        newSections.push(segmentName);

                        for (const ga of gaInfo) {
                            let regex = new RegExp(ga.regex);
                            console.log("GA: ", ga)
                            if (regex.test(segmentName)) {
                                console.log("Test passed: ", ga.template)
                                segmentName = ga.template;
                            }
                        }

                        let labelsArray = [];
                        for (const label of segment.shapes[0].labels) {
                            //@ts-ignore
                            labelsArray.push(label);
                        }

                        newArray.push({
                            id: segmentName,
                            labelData: labelsArray,
                            path: segment.shapes[0].path
                        });
                    } catch (error) {
                        try {
                            let labelsArray = [];
                            for (const label of segment.shapes[0].labels) {
                                //@ts-ignore
                                labelsArray.push(label);
                            }

                            newArray.push({
                                id: segment.name,
                                labelData: labelsArray,
                                path: segment.shapes[0].path
                            });

                        } catch (error2) {
                            console.error('error: ', error2, segment);
                        }
                    }
                });

                const groupedSections = newSections.reduce((acc, section) => {
                    console.log(section);
                    let key;
                
                    // Check for known prefix first
                    const knownPrefix = prefixes.find(p => section.startsWith(p));
                    if (knownPrefix) {
                        key = knownPrefix;
                    } else {
                        const match = section.match(/^([A-Za-z]*)(\d+)([A-Za-z]*)$/);
                        if (match) {
                            const prefix = match[1] || '';  // Letters before numbers
                            const number = match[2];        // Numeric part
                            const suffix = match[3] || '';  // Letters after numbers (for reference)
                
                            if (prefix && !number) {
                                key = prefix;
                            } else if (prefix && number) {
                                key = prefix;
                            } else if (!prefix && number) {
                                if (number.length <= 2) {
                                    key = '00';
                                } else if (number.length === 3) {
                                    key = number[0] + '00';
                                } else {
                                    const digits = number.slice(0, -2);
                                    key = digits + '00';
                                }
                            } else {
                                key = '00';
                            }
                        } else {
                            key = section;
                        }
                    }
                
                    if (!acc[key]) acc[key] = [];
                
                    acc[key].push(section);
                
                    return acc;
                }, {});

                setGroups(groupedSections);

                setOverlayData(newArray);
                setMapImage(`https://mapsapi.tmol.io/maps/geometry/image/${jsonData.pages[0].images[0].url}?removeFilters=ISM_Shadow&avertaFonts=true&app=PRD2663_EDP_NA`);
                handlers.close()
            }
        };

        fetchData();
    }, [eventid]);

    return (
        <>
            <LoadingOverlay visible={visible} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} classNames={OverlayCss} />
            <svg id="mapSvg" className={styles.svg} viewBox="0 0 10240 7680">
                <image href={mapImage || ''} x="0" y="0" height="100%" width="100%" preserveAspectRatio="xMidYMid slice" />
                {overlayData && overlayData.map((item) => {
                    return (
                        <g
                            id={item.id}
                            onClick={() => handleClick(item.id)}
                            onMouseEnter={() => setHoveredSection(item.id)}
                            onMouseLeave={() => setHoveredSection(null)}
                        >
                            <path
                                id={item.id}
                                d={item.path}
                                fill={selectedSections && selectedSections.includes(item.id) ? '#40bcff' : hoveredSection === item.id ? '#abceff' : '#cccccc'}
                                stroke="white"
                                strokeWidth="2"
                            />
                            {item && item.labelData && item.labelData.map((label) => {
                                let angle = label.angle * -1
                                return (
                                    <text
                                        fill="white"
                                        fontSize={label.size}
                                        transform={`rotate(${angle}, ${label.x}, ${label.y})`}
                                    >
                                        <tspan
                                            x={label.x}
                                            y={label.y}
                                            dy="1em"
                                            fontFamily="Arial"
                                            fill="white"
                                            textAnchor="middle"
                                        >
                                            {label.text}
                                        </tspan>
                                    </text>
                                )
                            })}
                        </g>
                    )
                })}
            </svg>

            <Center>
                <Group>
                    {Object.keys(groups).map((group) => (
                        <Button
                            onClick={(event) => {
                                groups[group].forEach((section) => {
                                    handleClick(section);
                                })
                            }}
                            color='gray'
                            variant='outline'
                            size="xs"
                        >
                            {group}
                        </Button>
                    ))}
                </Group>
            </Center>
        </>
    );
}