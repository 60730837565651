import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth0DecodedHash, Auth0ParseHashError, Auth0UserProfile, Auth0Error } from 'auth0-js';
import { auth } from '../../services/auth0.service.ts';
import { LoadingOverlay } from '@mantine/core';

export const PostAuthentication = () => {
    const location = useLocation()
    const navigate = useNavigate()

    console.log('rendered')

    const parseHash = (hash: string) => {
        auth.parseHash({ hash }, function (error: Auth0ParseHashError | null, result: Auth0DecodedHash | null) {
            if (error) {
                console.log('PARSING FAILED: ', error)
                //navigate('/login')

                return
            }
            if (result) {
                const { accessToken } = result

                if (accessToken) {
                    auth.client.userInfo(accessToken, function (error: Auth0Error | null, result2: Auth0UserProfile) {
                        if (error) {
                            console.log('USER INFO FAILED: ', error)
                            navigate('/login')
                            return
                        }

                        console.log('USER INFO SUCCESS: ', result)
                        localStorage.setItem('authToken', accessToken)
                        localStorage.setItem('userData', JSON.stringify(result2))
                        navigate('/dashboard')
                    })
                }
            }
        })
    }

    useEffect(() => {
        if (location.hash) {
            console.log('AUTHENTICATED: ', location.hash)
            parseHash(location.hash)
        } else {
            console.log('NOT AUTHENTICATED: ', location)
            navigate('/login')
        }
    }, [location])
    return (
        <>
            <LoadingOverlay visible={true} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        </>
    )
}