import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

const rootElement = document.getElementById('root');

if (!rootElement) {
    throw new Error("Could not find root element");
}

const root = ReactDOM.createRoot(rootElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);