import { useState } from 'react';
import { Group, Code, Stack } from '@mantine/core';
import {
    IconSettings,
    IconListDetails,
    IconList,
} from '@tabler/icons-react';
import classes from './NavbarSimple.module.css';
import React from 'react';
import UserButton from '../Navbar/UserButton.tsx';

const data = [
    { link: '', label: 'Event List', icon: IconList },
    { link: '', label: 'Settings', icon: IconSettings },
    { link: '', label: 'Changelog', icon: IconListDetails },
];

export default function NavbarSimple({ setComponentType }) {
    const [active, setActive] = useState('Event List');

    const links = data.map((item) => (
        <a
            className={classes.link}
            data-active={item.label === active || undefined}
            href={item.link}
            key={item.label}
            onClick={(event) => {
                event.preventDefault();
                setActive(item.label);
                setComponentType(item.label.toLowerCase().replace(' ', ''));
            }}
        >
            <item.icon className={classes.linkIcon} stroke={1.5} />
            <span>{item.label}</span>
        </a>
    ));

    return (
        <nav className={classes.navbar}>
            <Stack 
                h="100%"
                bg="var(--mantine-color-body)"
                align="stretch"
                justify="space-between"
            >
                <div className={classes.navbarMain}>
                    <Group className={classes.header} justify="space-between">
                        <Code fw={700}>ALPHA BUILD</Code>
                    </Group>
                    {links}
                </div>

                <div className={classes.specialDiv}>
                    <UserButton />
                </div>
            </Stack>
        </nav>
    );
}