import { AppShell, Burger, Text, Flex, Group, rem, Button, LoadingOverlay } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { Notifications, notifications } from '@mantine/notifications';
import React, { useState, } from 'react';
import NavbarSimple from '../Navbar/NavbarSimple.tsx';
import TableSort from './EventsTable/TableSort.tsx';
// @ts-ignore
import Settings from './Settings/Settings.tsx';
import Changelog from './Changelog/Changelog.tsx';
import ColorModeToggle from '../ColorModeToggle/ColorModeToggle.tsx';
const logo = process.env.PUBLIC_URL + "/logo.png";

export default function Dashboard() {
    const [opened, { toggle }] = useDisclosure();
    const [componentType, setComponentType] = useState('eventlist');
    const [activePage, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [eventAdded, setEventAdded] = useState(true);
    const [slots, setSlots] = useState(0);
    const [visible, handlers] = useDisclosure(false);
    const [enterprise, setEnterprise] = useState(false);

    React.useEffect(() => {
        if (eventAdded === false) return;

        console.log(process.env.REACT_APP_API_DOMAIN)

        handlers.open()
        fetch(`${process.env.REACT_APP_API_DOMAIN}/getEvents`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': JSON.parse(localStorage.userData).sub
            }
        })
            .then(response => {
                if (response.ok) {
                    handlers.close()
                    return response.json();
                }
                throw new Error('Request failed!');

            })
            .then(data => {
                setEnterprise(data.enterprise)
                setData(data.events)
                setSlots(data.allowedSlots)
                setEventAdded(false);
            })
    }, [eventAdded]);

    return (
        <AppShell
            header={{ height: 80 }}
            navbar={{
                width: 300,
                breakpoint: 'sm',
                collapsed: { mobile: !opened },
            }}
            padding="md"
        >
            <AppShell.Header>
                <Group
                    justify='space-between'
                    mt="md"
                >
                    <Flex
                        mih='100%'
                        gap="sm"
                        justify="flex-start"
                        align="center"
                        direction="row"
                        ml="md"
                    >
                        <Burger
                            opened={opened}
                            onClick={toggle}
                            hiddenFrom="sm"
                            size="sm"
                        />
                        <img src={logo} alt="Logo" style={{ height: 55 }} />
                        <Text size='lg'>Aurora Monitors</Text>
                    </Flex>
                    <ColorModeToggle />
                </Group>
            </AppShell.Header>

            <AppShell.Navbar p="md"> <NavbarSimple setComponentType={setComponentType} /> </AppShell.Navbar>

            <AppShell.Main>
                <Notifications />
                <LoadingOverlay visible={visible} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                {componentType === 'eventlist' ?
                
                    <TableSort page={activePage} data={data} setPage={setPage} setEventAdded={setEventAdded} allotedSlots={slots} usedSlots={data.length} enterprise={enterprise}/>
                    : componentType === 'settings' ?
                        <Settings />
                        : <Changelog />
                }
            </AppShell.Main>
        </AppShell>
    );
}