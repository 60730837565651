import { UnstyledButton, Group, Avatar, Text, rem, Menu } from '@mantine/core';
import { IconChevronRight, IconLogout } from '@tabler/icons-react';
import classes from './UserButton.module.css';
import React, { useState, useEffect } from 'react';
import { auth } from '../../services/auth0.service.ts';
import { AUTH0_CLIENT_ID } from '../../config.js';

export default function UserButton() {
    let [userData, setUserData] = useState({nickname: 'Mike Oxlong', email: 'mike@oxlong.com', avatar: 'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-8.png'})


    useEffect(() => {
        let parsedData = JSON.parse(localStorage.getItem('userData') || '{}')
        let newData = {
            nickname: parsedData.nickname || 'Mike Oxlong',
            email: parsedData.email || 'mike@oxlong.com',
            avatar: parsedData.picture || 'https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-8.png'
        }

        setUserData(newData)
    }, [])

    return (
        <Menu trigger="click-hover" openDelay={100} closeDelay={250} width='target' shadow='md'>
            <Menu.Target>
                <UnstyledButton className={classes.user}>
                    <Group>
                        <Avatar
                            src={userData.avatar}
                            radius="xl"
                        />

                        <div style={{ flex: 1 }}>
                            <Text size="sm" fw={500}>
                                {userData.nickname}
                            </Text>

                            <Text c="dimmed" size="xs">
                                {userData.email}
                            </Text>
                        </div>

                        <IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
                    </Group>
                </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item
                    color="red"
                    leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
                    onClick={() => {
                        localStorage.setItem('userData', '{}')
                        localStorage.setItem('authToken', '')

                        auth.logout({
                            returnTo: process.env.REACT_APP_LOGOUT,
                            clientID: AUTH0_CLIENT_ID
                        })
                    }}
                >Log Out</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
}