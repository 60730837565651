import { createTheme, MantineProvider } from '@mantine/core';
import Login from './components/Login/Login.tsx';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { PostAuthentication } from './components/Login/PostAuthentication.tsx';
import PrivateRoute from './components/Routes/PrivateRoute.tsx';
import Dashboard from './components/Dashboard/Dashboard.tsx';
import React from 'react';
import { ForgotPassword } from './components/Login/ForgotPassword/ForgotPassword.tsx';

function App() {
    return (
        <MantineProvider theme={createTheme('light')}>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot" element={<ForgotPassword />} />
                    <Route
                        path="/"
                        element={localStorage.getItem('authToken') ? <Navigate to="/dashboard" />: <Navigate to="/login" />} />
                    <Route path="/auth" element={<PostAuthentication />} />
                    <Route path="/dashboard" element={<PrivateRoute> <Dashboard /> </PrivateRoute>} />
                </Routes>
            </Router>
        </MantineProvider>
    );
}
export default App;