import React, { useState, useEffect } from "react";
import { Modal, TextInput, Center, ActionIcon, Card, Stack, Button, Text, Group, UnstyledButton, ScrollArea } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useForm } from '@mantine/form'

interface ManageProps {
    isManagerOpen: boolean;
    setIsManagerOpen: (isOpen: boolean) => void;
}

export default function WebhookManager({ isManagerOpen, setIsManagerOpen }: ManageProps) {
    const [webhooks, setWebhooks] = useState<Record<string, string>>({});
    const [adding, toggleAdding] = useState(false);

    useEffect(() => {
        async function fetchWebhooks() {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/getWebhookList`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': JSON.parse(localStorage.userData).sub
                    }
                });
                const data = await response.json();
                setWebhooks(data.webhooks);
            } catch (error) {
                console.error('Failed to fetch webhooks:', error);
            }
        }

        if (isManagerOpen) {
            fetchWebhooks();
        }

    }, [isManagerOpen]);

    const form = useForm({
        initialValues: {
            webhookName: '',
            webhookUrl: '',
        }
    });

    function removeKey(key: string) {
        let newWebhooks = { ...webhooks };
    
        delete newWebhooks[key];
    
        setWebhooks(newWebhooks);
    }

    function close() { // We're going to need this to save the webhooks to server
        setIsManagerOpen(false);
        toggleAdding(false);

        fetch(`${process.env.REACT_APP_API_DOMAIN}/updateWebhookList`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': JSON.parse(localStorage.userData).sub
            },
            body: JSON.stringify(webhooks),
        });
    }

    function handleSubmit(values) {
        setWebhooks({
            ...webhooks,
            [values.webhookName]: values.webhookUrl,
        });
        form.reset();
        toggleAdding(false);
    }

    return (
        <Modal
            opened={isManagerOpen}
            onClose={() => close()}
            title={"Webhook Management"}
            overlayProps={{
                backgroundOpacity: 0.55,
                blur: 3,
            }}
        >
            <ScrollArea>
                <Stack gap="xs">
                    {Object.keys(webhooks).map((key) => (
                        <Card shadow="sm" padding="sm" radius="md" withBorder>
                            <Group justify="space-between">
                                <Text>{key}</Text>

                                <UnstyledButton onClick={() => removeKey(key)}>
                                    <ActionIcon variant="transparent" size="lg" aria-label="Trash" color="red" >
                                        <IconTrash stroke={1} />
                                    </ActionIcon>
                                </UnstyledButton>
                            </Group>
                        </Card>
                    ))}


                    <Center>
                        <ActionIcon variant="transparent" color="gray" onClick={() => {
                            toggleAdding(!adding);
                        }}>
                            <IconPlus />
                        </ActionIcon>
                    </Center>
                </Stack>
            </ScrollArea>

            {adding && <Card shadow="sm" padding="sm" radius="md" withBorder>
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Stack gap="xs">
                        <TextInput
                            placeholder="Webhook Name"
                            {...form.getInputProps('webhookName')}
                        />
                        <TextInput
                            placeholder="Webhook URL"
                            {...form.getInputProps('webhookUrl')}
                        />
                        <Button type="submit">
                            Add Webhook
                        </Button>
                    </Stack>
                </form>
            </Card>}
        </Modal>
    );
}
