import {
    Paper,
    Title,
    Text,
    TextInput,
    Button,
    Container,
    Group,
    Anchor,
    Center,
    Box,
    rem,
    UnstyledButton,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import classes from './ForgotPassword.module.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from '../../../config.js';

export function ForgotPassword() {
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            email: '',
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        }
    });

    const [isSent, setIsSent] = useState(false)
    const navigate = useNavigate()

    return (
        <Container size={460} my={30}>
            <Title className={classes.title} ta="center">
                Forgot your password?
            </Title>
            <Text c="dimmed" fz="sm" ta="center">
                Enter your email to get a reset link
            </Text>

            <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
                {!isSent ? (
                    <form onSubmit={form.onSubmit((values) => {
                        fetch(`https://${AUTH0_DOMAIN}/dbconnections/change_password`, {
                            method: 'POST',
                            headers: {
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({
                                client_id: AUTH0_CLIENT_ID,
                                email: values.email,
                                connection: 'Username-Password-Authentication'
                            })
                        }).then((response) => {
                            if (response.ok) {
                                setIsSent(true);
                                setTimeout(() => {
                                    navigate('/login')
                                    setIsSent(false)
                                }, 5000)
                            }
                        })
                    })}>
                        <TextInput
                            label="Your email"
                            placeholder="me@auroramonitors.com" required
                            {...form.getInputProps('email')} />
                        <Group justify="space-between" mt="lg" className={classes.controls}>
                            <Anchor c="dimmed" size="sm" className={classes.control}>
                                <Center inline>
                                    <UnstyledButton type='button' onClick={() => {
                                        navigate('/login')
                                    }}>
                                        <Group>
                                            <IconArrowLeft style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                                            <Box ml={5}>Back to the login page</Box>
                                        </Group>
                                    </UnstyledButton>
                                </Center>
                            </Anchor>
                            <Button className={classes.control} type="submit">Reset password</Button>
                        </Group>
                    </form>
                ) : (
                    // Render something else when isSent is true
                    <div>Password reset email has been sent. <br />Sending you back to login page...  </div>
                )}
            </Paper>
        </Container>
    );
}