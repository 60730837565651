import React, { useEffect, useState } from 'react';
import {
    Modal,
    Button,
    Center,
    Divider,
    Checkbox,
    Group,
    Accordion,
    UnstyledButton,
    TextInput,
    MultiSelect,
    Stack,
    Text,
    CloseButton,
    rem
} from '@mantine/core';
import TMMap from './TMMap.tsx';
import styles from './FilteringModal.module.css';
import { notifications } from '@mantine/notifications';
import notifCss from '../notifications.module.css'
import { IconCheck, IconX } from '@tabler/icons-react';

if (typeof window !== 'undefined') {
    window.ResizeObserver = class ResizeObserver {
        observe() {
            // do nothing
        }
        unobserve() {
            // do nothing
        }
        disconnect() {
            // do nothing
        }
    };
}

export default function FilteringModal({ opened, close, eventitem }) {
    type GroupType = { low: number; high: number; id: number | null; };
    const [selectedGroup, setSelectedGroup] = useState<number | null>(0);
    const [ticketTypes, setTicketTypes] = useState<string[]>([]);
    const [gaInfo, setGaInfo] = useState<any>(null)

    const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
    const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;

    const [filterData, setFilterData] = useState<{
        singles: boolean;
        doubles: boolean;
        allowResale: boolean;
        excludeOffers: string[];
        groups: GroupType[][];
        selectedSections: string[][];
    }>({
        singles: false,
        doubles: true,
        allowResale: false,
        excludeOffers: ['Official Platinum'],
        groups: [
            [
                {
                    low: 0,
                    high: 0,
                    id: Date.now(),
                },
            ],
        ],
        selectedSections: [
            [],
        ],
    });

    function addGroup() {
        const dataCopy = [...filterData.groups];
        const newGroup = [
            {
                low: 0,
                high: 0,
                id: Date.now(),
            },
        ];
        dataCopy.push(newGroup);

        const copy2 = [...filterData.selectedSections];
        copy2.push([]);

        setFilterData({ ...filterData, groups: dataCopy, selectedSections: copy2 });
    }

    function removeGroup(number) {
        setFilterData(prevFilterData => {
            const dataCopy = [...prevFilterData.groups];
            dataCopy.splice(number, 1);
            return { ...prevFilterData, groups: dataCopy };
        });
    }

    function addFilter(groupIndex: number) {
        setFilterData(prevState => {
            const groupsCopy = [...prevState.groups];
            const newFilter: GroupType = {
                low: 0,
                high: 0,
                id: Date.now(),
            };
            groupsCopy[groupIndex].push(newFilter);
            return { ...prevState, groups: groupsCopy };
        });
    }

    function removeFilter(groupIndex: number, filterIndex: number) {
        setFilterData(prevState => {
            const groupsCopy = prevState.groups.map(group => [...group]);
            if (groupsCopy[groupIndex].length > 1) {
                groupsCopy[groupIndex] = groupsCopy[groupIndex].filter((_, index) => index !== filterIndex);
            }
            return { ...prevState, groups: groupsCopy };
        });
    }

    function selectSection(groupIndex: number, section: string) {
        setFilterData(prevState => {
            const selectedSectionsCopy = prevState.selectedSections.map(group => [...group]);
            selectedSectionsCopy[groupIndex].push(section);
            return { ...prevState, selectedSections: selectedSectionsCopy };
        });
    }

    function getSectionsSelected(groupIndex: number) {
        return filterData.selectedSections[groupIndex];
    }

    function deselectSection(groupIndex: number, section: string) {
        setFilterData(prevState => {
            const selectedSectionsCopy = prevState.selectedSections.map(group => [...group]);
            selectedSectionsCopy[groupIndex] = selectedSectionsCopy[groupIndex].filter(selectedSection => selectedSection !== section);
            return { ...prevState, selectedSections: selectedSectionsCopy };
        });
    }

    useEffect(() => {
        async function fetchData() {
            if (eventitem && eventitem.eventid) {
                const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/getFilters?eventId=${eventitem.eventid}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': JSON.parse(localStorage.userData).sub
                    }
                });

                const response2 = await fetch(`${process.env.REACT_APP_API_DOMAIN}/getOffers?eventId=${eventitem.eventid}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': JSON.parse(localStorage.userData).sub
                    }
                });
    
                if (response.ok) {
                    const responseData = await response.json();

                    setGaInfo(responseData["eventData"].gaInfo)

                    setFilterData(responseData["events"][eventitem.eventid] ? responseData["events"][eventitem.eventid] : {
                        singles: false,
                        doubles: true,
                        allowResale: false,
                        excludeOffers: ['Official Platinum'],
                        groups: [
                            [
                                {
                                    low: 0,
                                    high: null,
                                    id: null,
                                },
                            ],
                        ],
                        selectedSections: [
                            [],
                        ],
                    });
                }

                if (response2.ok) {
                    const responseData = await response2.json();
                    setTicketTypes(responseData);
                }
            }
        }

        fetchData()
    }, [eventitem]);

    return (
        <>
            <Modal
                opened={opened}
                onClose={() => {
                    setSelectedGroup(0)
                    close();
                }}
                title={eventitem && `${eventitem.eventname} - ${eventitem.eventlocation} | ${eventitem.eventDate} | ${eventitem.eventid}`}
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
                centered
                size='70%'
            >
                <Text
                    color="gray"
                >Price Range: {eventitem && eventitem.prices}</Text>
                <TMMap
                    eventid={eventitem && eventitem.eventid}
                    groupid={selectedGroup}
                    addSection={selectSection}
                    removeSection={deselectSection}
                    getSelectedSections={getSectionsSelected}
                    gaInfo={gaInfo}
                />

                <Group
                    justify='center'
                    mt='lg'
                >
                    <Checkbox
                        label="Singles"
                        onChange={(event) => {
                            setFilterData({ ...filterData, singles: event.target.checked });
                        }}
                        checked={filterData.singles}
                    />
                    <Checkbox
                        label="Doubles"
                        onChange={(event) => {
                            setFilterData({ ...filterData, doubles: event.target.checked });
                        }}
                        checked={filterData.doubles}
                    />
                </Group>
                <Divider my='md' />

                <MultiSelect
                    searchable
                    label="Exclude Offers"
                    data={ticketTypes}
                    clearable
                    className={styles.multiSelect}
                    value={filterData.excludeOffers}
                    onChange={(value) => {
                        setFilterData({ ...filterData, excludeOffers: value });
                    }}
                />

                <Divider my='md' />

                <Accordion defaultValue="Group 1" onChange={(value) => {
                    if (value) {
                        setSelectedGroup(parseInt(value.split(' ')[1]) - 1);
                    }
                }}>
                    {filterData.groups.map((group, index) => (
                        <Accordion.Item key={group[0].id} value={`Group ${index + 1}`}>
                            <Accordion.Control>
                                <Group
                                    justify='space-between'
                                >
                                    {`Group ${index + 1}`}
                                    <CloseButton mr='md' onClick={() => removeGroup(index)} />
                                </Group>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Group>
                                    <Stack>
                                        {group.map((filter, filterIndex) => (
                                            <Group key={filter.id}>
                                                <TextInput
                                                    value={filter.low ? filter.low.toString() : ''}
                                                    placeholder='Low'
                                                    onChange={(event) => {
                                                        const newFilterData = [...filterData.groups];
                                                        newFilterData[index][filterIndex].low = parseInt(event.target.value);
                                                        setFilterData({ ...filterData, groups: newFilterData });
                                                    }}
                                                />
                                                -
                                                <TextInput
                                                    value={filter.high ? filter.high.toString() : ''}
                                                    placeholder='High'
                                                    onChange={(event) => {
                                                        const newFilterData = [...filterData.groups];
                                                        newFilterData[index][filterIndex].high = parseInt(event.target.value);
                                                        setFilterData({ ...filterData, groups: newFilterData });
                                                    }}
                                                />
                                                <UnstyledButton onClick={() => removeFilter(index, filterIndex)}>
                                                    <Text size='lg' fw={700}> - </Text>
                                                </UnstyledButton>
                                            </Group>
                                        ))}

                                        <Center>
                                            <UnstyledButton onClick={() => addFilter(index)}>
                                                <Text
                                                    size='lg'
                                                    fw={700}
                                                >
                                                    +
                                                </Text>
                                            </UnstyledButton>
                                        </Center>
                                    </Stack>
                                </Group>
                            </Accordion.Panel>
                        </Accordion.Item>
                    ))}
                </Accordion>

                <Center
                    mt='sm'
                >
                    <UnstyledButton className={styles.addGroup} onClick={addGroup}>
                        + Add Group
                    </UnstyledButton>
                </Center>

                <Divider my='md' />

                <Center>
                    <Button variant='light' mt="md" onClick={() => {
                        const updatedValue = filterData.excludeOffers.map(item => item === 'Face Value Exchange' ? 'Verified Resale' : item);
                        setFilterData({ ...filterData, excludeOffers: updatedValue });

                        fetch(`${process.env.REACT_APP_API_DOMAIN}/filterEvents`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'x-api-key': JSON.parse(localStorage.userData).sub
                            },
                            body: JSON.stringify({
                                eventList: {
                                    [eventitem.eventid]: filterData
                                }
                            }),
                        })
                            .then(response => {
                                if (response.ok) {
                                    notifications.show({
                                        message: 'Filters saved successfully!',
                                        classNames: notifCss,
                                        icon: checkIcon,
                                        color: 'teal'
                                    })

                                    return response.text();
                                }
                                notifications.show({
                                    message: `Error: ${response.status} - ${response.statusText}`,
                                    classNames: notifCss,
                                    icon: xIcon,
                                    color: 'teal'
                                })
                                throw new Error('Request failed!');
                            })
                    }}>
                        Submit
                    </Button>
                </Center>
            </Modal>
        </>
    )
}