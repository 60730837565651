import {
    TextInput,
    PasswordInput,
    Anchor,
    Paper,
    Title,
    Text,
    Container,
    Group,
    Button,
} from '@mantine/core';
import classes from './Login.module.css';
import React from 'react';
import { useForm } from '@mantine/form'
import { auth } from '../../services/auth0.service.ts';
import { AUTH0_LOGIN_REDIRECT_URI, AUTH0_LOGIN_RESPONSE_TYPE, AUTH0_REALM } from '../../config.js';
import { Auth0Error } from 'auth0-js';
import { useNavigate } from 'react-router-dom';
import loginCss from './Paper.module.css'

console.log(AUTH0_LOGIN_REDIRECT_URI)


export default function Login() {
    const navigate = useNavigate()

    const form = useForm({
        initialValues: {
            email: '',
            password: '',
            remember: null,
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        }
    });

    const handleSubmit = (values) => {
        console.log(values)
        auth.login({
            username: values.email,
            password: values.password,
            realm: AUTH0_REALM,
            redirectUri: AUTH0_LOGIN_REDIRECT_URI,
            responseType: AUTH0_LOGIN_RESPONSE_TYPE
        }, function (error: Auth0Error | null, result: any) {
            if (error) {
                console.log('LOGIN FAILED: ', error)
            }

            console.log('LOGIN SUCCESS: ', result)
        })
    }

    return (
        <div className={classes.bg}>
            <Container size={420} py={40}>
                <Title ta="center" className={classes.title}>
                    Welcome back!
                </Title>
                <Text c="dimmed" size="sm" ta="center" mt={5}>
                    Do not have an account yet? Contact troy0532 on Discord to purchase a license
                </Text>

                <Paper
                    withBorder
                    shadow="md"
                    p={30}
                    mt={30}
                    radius="md"
                >
                    <form onSubmit={form.onSubmit(handleSubmit)}>
                        <TextInput
                            label="Email"
                            placeholder="you@auroramonitors.com"
                            required
                            {...form.getInputProps('email')} 
                        />
                        <PasswordInput
                            label="Password"
                            placeholder="Your password"
                            required mt="md"
                            {...form.getInputProps('password')} 
                        />
                        <Group justify="space-between" mt="lg">
                            <Anchor
                                component="button"
                                type="button"
                                size="sm"
                                onClick={() => {
                                    navigate('/forgot')
                                }}
                            >
                                Forgot password?
                            </Anchor>
                        </Group>
                        <Button fullWidth mt="xl" type="submit">
                            Sign in
                        </Button>
                    </form>
                </Paper>
            </Container>
        </div>
    );
}
