import { Modal, Button, Textarea, Center, rem } from '@mantine/core';
import React, { useState } from 'react';
import { notifications } from '@mantine/notifications';
import notifCss from '../notifications.module.css'
import { IconCheck, IconExclamationMark } from '@tabler/icons-react';

export default function AddEventModal({ opened, close, setEventAdded }) {
    const [value, setValue] = useState('');
    const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;
    const exclamationIcon = <IconExclamationMark style={{ width: rem(20), height: rem(20) }} />;

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Add Events"
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
            >
                <Textarea
                    resize="vertical"
                    placeholder="Event IDs"
                    autosize
                    minRows={10}
                    onChange={(event) => setValue(event.currentTarget.value)}
                />

                <Center>
                    <Button variant='light' mt="md" onClick={async () => {
                        let eventList = {};

                        value.split('\n').forEach((eventid) => {
                            if (eventid.startsWith('http') || eventid.startsWith('www')) {
                                return;
                            }

                            eventList[eventid] = {
                                singles: false,
                                doubles: true,
                                allowResale: false,
                                excludeOffers: ['Official Platinum'],
                                groups: [
                                    [
                                        {
                                            low: null,
                                            high: null,
                                            id: null,
                                        },
                                    ],
                                ],
                                selectedSections: [
                                    [],
                                ],
                            }
                        });

                        const request = fetch(`${process.env.REACT_APP_API_DOMAIN}/addEvents`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'x-api-key': JSON.parse(localStorage.userData).sub
                            },
                            body: JSON.stringify(Object.keys(eventList)),
                        })

                        if ((await request).status === 200) {
                            setEventAdded(true);

                            notifications.show({
                                message: 'Events added successfully!',
                                classNames: notifCss,
                                icon: checkIcon,
                                color: 'teal'
                            })

                            close();
                        } else if ((await request).status === 207) {
                            setEventAdded(true);

                            notifications.show({
                                message: 'Unable to add certain events, you hit the limit!',
                                classNames: notifCss,
                                icon: exclamationIcon,
                                color: 'orange'
                            })

                            close();
                        }
                    }}>
                        Submit
                    </Button>
                </Center>
            </Modal>
        </>
    );
}