import { Container, Title, Accordion } from '@mantine/core';
import classes from './FaqSimple.module.css';
import React from 'react';

export default function Changelog() {
  return (
    <Container size="sm" className={classes.wrapper}>
      <Title ta="center" className={classes.title}>
        Changelog
      </Title>

      <Accordion variant="separated">
        <Accordion.Item className={classes.item} value="reset-password">
          <Accordion.Control><strong>Alpha Build v0</strong></Accordion.Control>
          <Accordion.Panel>{"Welcome to the Aurora Monitors alpha build. Enjoy our lightning fast monitors and report any feedback or bugs to a developer."}</Accordion.Panel>
        </Accordion.Item>

      </Accordion>
    </Container>
  );
}